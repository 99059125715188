/* Font Picker Overrides */

div[id^="font-picker"] {
  display: block !important;
  width: 100% !important;
  margin: 0.25rem 0 !important;
  font-size: 1em !important;
  background-color: #fff !important;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23666666%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: right 0.75em top 50% !important;
  background-size: 0.75em auto !important;
  border: 1px solid #ddd !important;
  box-shadow: n0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
  border: 0 !important;
  border-radius: 5px !important;
  transition: box-shadow 0.15s ease !important;
}

div[id^="font-picker"] .dropdown-button {
  height: auto !important;
  padding: 0.5em !important;
  background-color: transparent !important;
}

div[id^="font-picker"] .dropdown-button p {
  max-width: 200px !important;
  padding-right: 2.25em !important;
  overflow: hidden !important;
  text-align: left !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

div[id^="font-picker"] .dropdown-button:hover,
div[id^="font-picker"] .dropdown-button:focus {
  background-color: transparent !important;
  cursor: pointer !important;
  outline: unset !important;
}

div[id^="font-picker"] .dropdown-button:focus {
  outline: 2px auto rgba(19, 124, 189, 0.6) !important;
  outline-offset: 2px !important;
}

div[id^="font-picker"] .dropdown-icon {
  display: none !important;
}

div[id^="font-picker"] ul {
  background-color: #fff !important;
  box-shadow: 0 1px 0.125rem rgba(0, 0, 0, 0.25) !important;
}

div[id^="font-picker"] ul li button:hover,
div[id^="font-picker"] ul li button:focus {
  background-color: #eee !important;
}
